<template>
  <div class="px-9 pt-8">
    <div
      class="flex gap-2 mb-10 font-medium cursor-pointer"
      @click="$router.go(-1)"
    >
      <img src="@/assets/icons/arrow-small-left.svg" alt="" />
      Back
    </div>
    <div class="border border-outlineGray p-8 mt-10">
      <div class="flex items-center justify-between">
        <div class="flex gap-2 items-center">
          <p class="text-2xl font-bold text-dark-800">
            {{
              callDetails.serviceType
                ? callDetails.serviceType.split("_").join(" ")
                : ""
            }}
          </p>
          <div class="rounded-md px-3 py-1 bg-lighterGreen text-primary">
            {{ receiptInfo.companyName }}
          </div>
        </div>
        <div class="flex gap-6">
          <span
            @click="isOpen = true"
            class="w-full font-normal text-base cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-7 py-3"
            >View Receipt</span
          >
          <div class="flex gap-5 relative">
            <easiButton
              type="submit"
              class="w-full"
              @click="showDropdown = !showDropdown"
            >
              <div class="flex justify-center items-center gap-2 px-4">
                <span class="text-sm"> Download Receipt </span>

                <img
                  src="@/assets/icons/arrow-down-tray.svg"
                  class="w-6 h-6"
                  alt=""
                />
              </div>
            </easiButton>
            <div
              v-show="showDropdown"
              class="rounded-xl flex p-1 z-10 flex-col w-64 border-t text-dark-800 border-dark-100 bg-white absolute -left-24 md:-left-12 mt-1 -right-4 top-14 py-2 shadow-lg text-sm"
            >
              <div
                class="flex items-center gap-2 px-5 py-3 cursor-pointer relative hover:bg-mint rounded-md"
                @click="handleDownload('company')"
              >
                <div
                  class="btn-spinner"
                  v-if="loaders.includes('company')"
                ></div>

                <img
                  v-else
                  src="@/assets/icons/grey-tray.svg"
                  class="w-5 h-5"
                  alt=""
                />
                <span>Company Receipt</span>
              </div>
              <div
                class="flex items-center gap-2 px-5 py-3 cursor-pointer relative hover:bg-mint rounded-md"
                @click="handleDownload('all')"
              >
                <div class="btn-spinner" v-if="loaders.includes('all')"></div>

                <img
                  v-else
                  src="@/assets/icons/grey-tray.svg"
                  class="w-5 h-5"
                  alt=""
                />
                <span>All Employee Receipt</span>
              </div>
            </div>
            <div
              @click="(showDropdown = false), (selected = '')"
              :class="!showDropdown ? 'pointer-events-none' : ''"
              class="bg-transparent fixed inset-0"
            ></div>
          </div>
        </div>
      </div>
      <p class="mt-6 text-sm text-grey">
        We prioritise your account security. Secret keys can only be viewed and
        downloaded once. Replace old keys with new ones immediately for your
        account's safety
      </p>
      <hr class="my-6" />
      <div class="grid grid-cols-3 gap-4">
        <div class="bg-outlineGray p-4 flex flex-col rounded-2xl">
          <p class="text-dark-200 font-medium text-base">Session ID</p>
          <p class="text-dark-800 font-normal">
            {{ callDetails.sessionId ? callDetails.sessionId : "" }}
          </p>
        </div>
        <div class="bg-outlineGray p-4 flex flex-col rounded-2xl">
          <p class="text-dark-200 font-medium text-base">Date of Payment</p>
          <p class="text-dark-800 font-normal">
            {{
              callDetails.createdAt
                ? formatDateString(callDetails.createdAt)
                : ""
            }}
          </p>
        </div>
        <div class="bg-outlineGray p-4 flex flex-col rounded-2xl">
          <p class="text-dark-200 font-medium text-base">Time of Payment</p>
          <p class="text-dark-800 font-normal">
            {{
              callDetails.createdAt
                ? convertTimestamp(callDetails.createdAt)
                : ""
            }}
          </p>
        </div>
        <div class="bg-outlineGray p-4 flex flex-col rounded-2xl">
          <p class="text-dark-200 font-medium text-base">Total Amount Paid</p>
          <p class="text-dark-800 font-normal">
            {{
              callDetails.amountPaid
                ? formatAmount(callDetails.amountPaid)
                : formatAmount(0)
            }}
          </p>
        </div>
        <div class="bg-outlineGray p-4 flex flex-col rounded-2xl">
          <p class="text-dark-200 font-medium text-base">API Endpoint Used</p>
          <p class="text-dark-800 font-normal">
            {{
              callDetails.serviceType
                ? callDetails.serviceType.split("_").join(" ")
                : ""
            }}
          </p>
        </div>
        <div class="bg-outlineGray p-4 flex flex-col rounded-2xl">
          <p class="text-dark-200 font-medium text-base">No. of Employees</p>
          <p class="text-dark-800 font-normal">
            {{ callDetails.totalStaffNo ? callDetails.totalStaffNo : "" }}
          </p>
        </div>
      </div>

      <hr class="my-6" />
      <div class="grid grid-cols-2 gap-4">
        <div class="bg-outlineGray p-4 flex flex-col rounded-2xl">
          <p class="text-dark-200 font-medium text-base">PAYE ID</p>
          <p class="text-dark-800 font-normal">
            {{ callDetails.payeId ? callDetails.payeId : "" }}
          </p>
        </div>
        <div class="bg-outlineGray p-4 flex flex-col rounded-2xl">
          <p class="text-dark-200 font-medium text-base">Payment Status</p>
          <p
            v-if="callDetails.status"
            class="font-normal capitalize"
            :class="
              callDetails.status === 'PROCESSING'
                ? 'text-lightBorder'
                : api.status === 'FAILED'
                ? 'text-error'
                : 'text-success'
            "
          >
            {{ callDetails.status ? callDetails.status : "" }}
          </p>
        </div>
      </div>
    </div>
    <div class="border border-outlineGray p-8 mt-7">
      <p class="font-medium text-xl mb-6">Employee Information/Receipt</p>
      <div class="grid grid-cols-10 gap-6 items-center">
        <div class="col-span-10">
          <ApiDashBoardSearch
            @update:modelValue="handleSearch"
            placeholder="Search employee name or amount"
          />
        </div>
      </div>

      <hr class="mt-6 mb-4" />

      <ApiDashboardTable2 :data="paginatedItems" />
      <TaxPagination
        :totalItems="employees.length"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @page-change="handlePageChange"
      ></TaxPagination>
    </div>
    <easiRoundLoader v-if="loading" />
    <easiModal
      :showCloseButton="false"
      :persistence="false"
      rounded="none"
      :isHeader="false"
      compliance
      v-if="isOpen"
      @close="isOpen = false"
    >
      <div class="px-12 relative">
        <img
          src="@/assets/img/receiptCurve.svg"
          class="absolute right-0"
          alt="logo"
        />

        <div class="flex justify-between pt-9">
          <img src="@/assets/img/eaziLogo.svg" class="" alt="logo" />

          <div class="text-deep text-2xl">Payroll Receipt</div>
        </div>
        <div class="text-bodyNormal mt-1 text-2xl flex justify-end">
          NO:{{ receiptInfo.sessionId }}
        </div>

        <div
          class="rounded bg-lightAsh break-all border border-outline py-3 px-6 text-[10px] mb-10 mt-4"
        >
          <div class="flex justify-between">
            <div class="flex flex-col">
              <p>{{ receiptInfo.companyName }}</p>
              <p>{{ receiptInfo.country }}</p>
              <p>{{ receiptInfo.companyPhone }}</p>
            </div>
            <div>
              <p>{{ receiptInfo.address }}</p>
            </div>
          </div>
        </div>
        <div class="bg-primary rounded-t rounded-tr-2xl px-7 py-3">
          <p class="text-white">Receipt Details</p>
        </div>
        <div class="bg-newAsh border border-outline rounded-b-2xl">
          <div class="grid grid-cols-2 border-b border-outline">
            <p class="pl-6 py-3 text-xs border-r border-outline">
              Business Name
            </p>
            <p class="text-right py-3 text-xs font-bold pr-6">
              {{ company.companyName }}
            </p>
          </div>
          <div class="grid grid-cols-2 border-b border-outline">
            <p class="pl-6 py-3 text-xs border-r border-outline">
              Product Name
            </p>
            <p class="text-right py-3 text-xs font-bold pr-6">
              {{ receiptInfo.productName ? receiptInfo.productName : "" }}
            </p>
          </div>
          <div class="grid grid-cols-2 border-b border-outline">
            <p class="pl-6 py-3 text-xs border-r border-outline">
              Product Pencode
            </p>
            <p class="text-right py-3 text-xs font-bold pr-6">
              {{ receiptInfo.productPenCode }}
            </p>
          </div>
          <div
            class="grid grid-cols-2 items-center border-b border-outline bg-newAsh"
          >
            <p class="pl-6 py-3 text-xs border-r border-outline">
              Date of Payment
            </p>
            <div class="flex flex-col text-right py-3 text-xs pr-6">
              <p class="font-bold">
                {{
                  callDetails.createdAt
                    ? formatDateString(callDetails.createdAt)
                    : ""
                }}
              </p>
            </div>
          </div>
          <div class="grid grid-cols-2 border-b border-outline">
            <p class="pl-6 py-3 text-xs border-r border-outline">
              Number of Staff paid
            </p>
            <p class="text-right py-3 text-xs font-bold pr-6">
              {{ callDetails.totalStaffNo ? callDetails.totalStaffNo : "" }}
            </p>
          </div>
          <div class="grid grid-cols-2 bg-newAsh border-b border-outline">
            <p class="pl-6 py-3 text-xs border-r border-outline">Session ID</p>
            <p class="text-right py-3 text-xs font-bold pr-6">
              {{ callDetails.sessionId ? callDetails.sessionId : "" }}
            </p>
          </div>
          <div class="grid grid-cols-2">
            <p class="pl-6 py-3 text-xs border-r border-outline">
              Status of Payment
            </p>
            <p
              class="text-right py-3 text-xs font-bold pr-6"
              :class="
                callDetails.status === 'PROCESSING'
                  ? 'text-lightBorder'
                  : api.status === 'FAILED'
                  ? 'text-error'
                  : 'text-success'
              "
            >
              {{ callDetails.status ? callDetails.status : "" }}
            </p>
          </div>
          <div class="grid grid-cols-2">
            <p class="pl-6 py-3 text-xs border-r border-outline">
              Total Amount Paid
            </p>
            <p class="text-right py-3 text-xs font-bold pr-6">
              {{
                callDetails.amountPaid
                  ? formatAmount(callDetails.amountPaid)
                  : formatAmount(0)
              }}
            </p>
          </div>
        </div>

        <p class="text-center text-xs mt-10">
          If you have any question please don’t hesitate to visit our
          <a
            class="text-blue-500"
            target="_blank"
            href="https://master--eaziproduct.netlify.app/admin/support/talk-to-us"
          >
            FAQ</a
          >

          page, or <br />
          contact support via
          <a
            class="text-blue-500"
            target="_blank"
            href="mailto:hi@myeazipay.com"
          >
            Hi@myeazipay.com</a
          >
        </p>
        <img
          src="@/assets/img/lastCurve.svg"
          class="absolute left-0 -bottom-4"
          alt="logo"
        />
      </div>
    </easiModal>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";

import { useToast } from "vue-toastification";
import axios from "axios";
import ApiDashboardTable2 from "@/components/ApiDashboard/ApiDashboardTable2.vue";
import ApiDashBoardSearch from "@/components/ApiDashboard/ApiDashBoardSearch.vue";
import TaxPagination from "@/components/TaxFiling/TaxPagination.vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
const { formatAmount, formatDateString, downloadBase64 } = helperFunctions;

const showDropdown = ref(false);
const toast = useToast();
const route = useRoute();
const store = useDataStore();
const isOpen = ref(false);

const { query } = store;

onMounted(() => {
  getCallDetails();
});
const loading = ref(false);
const callDetails = ref({});
const company = ref({});
const receiptInfo = ref({});
const employees = ref([]);
const searchQuery = ref("");
const pageSize = ref(10);

const handleSearch = (e) => {
  searchQuery.value = e;
  currentPage.value = 1; // Reset page number when searching
};
const paginatedItems = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return filteredEmployees.value.slice(start, end);
});
const filteredEmployees = computed(() => {
  return employees.value.filter((item) => {
    return (
      item.firstName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      item.lastName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      item.amountPaid.toString().includes(searchQuery.value)
    );
  });
});
function convertTimestamp(timestamp) {
  // Step 1: Convert timestamp to a Date object
  let date = new Date(timestamp);

  // Step 2: Extract hours, minutes, and seconds
  let hours = date.getUTCHours().toString().padStart(2, "0");
  let minutes = date.getUTCMinutes().toString().padStart(2, "0");
  let seconds = date.getUTCSeconds().toString().padStart(2, "0");

  // Constructing the formatted time string
  let formattedTime = `${hours}:${minutes}:${seconds}`;

  return formattedTime;
}
const getCallDetails = async () => {
  try {
    loading.value = true;

    const res = await query({
      endpoint: "ViewCallDetail",
      payload: {
        input: {
          callId: route.params.id,
        },
      },
      service: "API",
    });

    if (res.success) {
      callDetails.value = res.data.call;
      company.value = res.data.company;
      employees.value = res.data.employeeDetailList;
      receiptInfo.value = res.data.receiptInfo;
      // tableData.value = res.data;
      // store.$patch({
      //   apiCalls: res.data,
      // });
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};
const loaders = ref([]);
const handleDownload = async (type) => {
  try {
    loaders.value.push(type);

    const res = await query({
      endpoint: `${
        type === "company"
          ? "DownloadCompanyReceipt"
          : "DownloadAllEmployeeReceipt"
      }`,
      payload: {
        input: {
          callId: route.params.id,
        },
      },
      service: "API",
    });
    if (res.success) {
      console.log("success", res);
      const base64 = type === "company" ? res.data.base64 : res.data.zipfile;
      const fileName =
        type === "company" ? res.data.fileName : res.data.zipFileName;
      type === "company"
        ? downloadBase64(base64, fileName)
        : downloadBase64(base64, fileName, undefined, true);
    } else {
      toast.error(" Download failed");
    }
  } catch (err) {
    console.log(err);
  } finally {
    loaders.value = loaders.value.filter((id) => id !== id);
  }
  return;
 
};
const currentPage = ref(1);
const handlePageChange = (page) => {
  this.currentPage = page;
};
</script>

<style lang="scss" scoped>
.btn-spinner {
  height: 16px;
  width: 16px;
  border: 1.4px solid green;
  border-top-color: transparent;
  animation: spin 0.5s linear infinite;
  border-radius: 99px;
  transition: width 0.25s ease;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
