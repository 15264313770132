<template>
  <div>
    <table v-if="employees.length" class="min-w-full bg-white border-gray-300">
      <thead>
        <tr class="text-left uppercase">
          <th class="py-5 px-4 border-b">S/N</th>
          <th class="py-5 px-4 border-b">Employee name</th>
          <th class="py-5 px-4 border-b">paye id</th>
          <th class="py-5 px-4 border-b">amount paid</th>

          <th class="py-5 px-4 border-b"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="(emp, index) in employees" :key="index">
          <td class="py-5 px-4 border-b">{{ index + 1 }}</td>
          <td class="py-5 px-4 border-b">
            {{ emp.firstName + " " + emp.lastName }}
          </td>
          <td class="py-5 px-4 border-b">{{ emp.payeId }}</td>
          <td class="py-5 px-4 border-b">{{ formatAmount(emp.amountPaid) }}</td>

          <td class="py-5 px-4 border-b">
            <div
              class="bg-highlight cursor-pointer p-2 flex items-center justify-center gap-4 rounded-2xl"
              @click="download(emp.employeeId)"
            >
              <div>Save</div>
              <div
                class="btn-spinner"
                v-if="loaders.includes(emp.employeeId)"
              ></div>

              <img
                v-else
                src="@/assets/icons/grey-tray.svg"
                class="w-4 h-4"
                alt=""
              />
            </div>
          </td>
        </tr>

        <!-- Add more rows as needed -->
      </tbody>
    </table>
    <div v-else class="flex justify-center font-semibold text-xl mt-5">
      No Employees
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import axios from "axios";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const store = useDataStore();
const toast = useToast();
const { query } = store;

const props = defineProps({
  data: {
    type: Object,
    // required: true,
  },
  showAddNewButton: {
    type: Boolean,
    default: false,
  },
});
const watchData = computed(() => props.data);
const employees = ref([]);
const { formatAmount, formatDateString,downloadBase64 } = helperFunctions;
const route = useRoute();

watch(watchData, () => {
  employees.value = props.data || [];
});

const loaders = ref([]);
const download = async (id) => {
  try {
    loaders.value.push(id);
    const res = await query({
      endpoint: "DownloadEmployeeReceipt",
      payload: {
        input: {
          callId: route.params.id,
          employeeId: id,
        },
      },
      service: "API",
    });
    if (res.success) {
      const base64 = res.data.base64;
      const fileName = res.data.fileName;
      downloadBase64(base64, fileName);
    } else {
      toast.error(" Download failed");
    }
  } catch (err) {
    console.log(err);
  } finally {
    loaders.value = loaders.value.filter((id) => id !== id);
  }
};
</script>

<style lang="scss" scoped>
.btn-spinner {
  height: 16px;
  width: 16px;
  border: 1.4px solid green;
  border-top-color: transparent;
  animation: spin 0.5s linear infinite;
  border-radius: 99px;
  transition: width 0.25s ease;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
